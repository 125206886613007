<template>
  <div>
    <!-- <v-btn
      v-if="currentEntity && currentEntity.entity.actions.csvDownload"
      @click="downloadCsv"
      class="my-md-4"
    >
      DownLoad Csv
    </v-btn> -->
    <DataTable
      :headers="headers"
      :tenantList="tenantList"
      :currentTenant="currentTenant"
      :currentEntity="currentEntity"
      :showCSVBTN="true"
      :items="items"
      :actions="entityActions"
      :filters="entityFilters"
      :pagination="pagination"
      @setCurrentTenant="setCurrentTenant"
      @click:action="handleAction"
      @click:removeChip="removeChip"
      @click:viewAction="rowAction('view', $event)"
      @updateTenantList="updateTenantList"
    >
    </DataTable>

    <!-- <CModal
      :title="entityModalTitle"
      :show.sync="showEntityModal"
      @update:show="handleEntityModal"
    >
      <template v-if="showEntityModal && action == 'filter'">
        <form ref="formData">
          <section v-for="field in fields" :key="field.dataTableID">
            <InputField
              :action="action"
              :field="field"
              v-model="formData[field.dataTableID]"
            />
          </section>
        </form>
      </template>

      <template v-if="showEntityModal && action == 'create'">
        <form ref="formData" class="px-3">
          <section
            v-for="field in fields"
            :key="field.schemaID || field.dataTableID"
          >
            <InputField
              :field="field"
              v-model="formData[field.schemaID || field.dataTableID]"
            />
          </section>
        </form>
      </template>

      <template slot="footer-wrapper">
        <div class="d-flex py-3 px-5">
          <CButton
            @click="handleEntityModal('confirm')"
            color="info"
            class="mr-1"
          >
            <span v-if="!is_loading">{{ entityModalAction }}</span>
            <CSpinner v-if="is_loading" grow size="sm" />
          </CButton>
          <button @click="resetForm" class="btn btn-secondary mr-1">
            Reset
          </button>
          <button
            @click="handleEntityModal('cancel')"
            class="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </template>
    </CModal> -->

    <v-dialog @update:show="handleEntityModal" v-model="dialog" width="520">
      <v-card>
        <v-card-title>
          <span>{{ entityModalTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <template v-if="dialog && action == 'filter'">
            <form ref="formData form-position">
              <div v-if="fields.length > 5">
                <section
                  v-for="field in fields.slice(0, 5)"
                  :key="field.dataTableID"
                >
                  <h3>{{ field.firstName }}</h3>
                  <InputField
                    :action="action"
                    :field="field"
                    v-model="formData[field.dataTableID]"
                  />
                </section>
                <div v-if="filterField">
                  <section
                    v-for="field in fields.slice(5, fields.length)"
                    :key="field.dataTableID"
                  >
                    <h3>{{ field.firstName }}</h3>
                    <InputField
                      :action="action"
                      :field="field"
                      v-model="formData[field.dataTableID]"
                    />
                  </section>
                </div>
                <v-btn
                  v-blur
                  @click="toggleFilters"
                  color="grey lighten-1 white--text"
                  class="mt-2 ml-0 view-more-btn"
                >
                  {{ viewButtonText }}
                </v-btn>
              </div>
              <div v-else>
                <section v-for="field in fields" :key="field.dataTableID">
                  <h3>{{ field.firstName }}</h3>
                  <InputField
                    :action="action"
                    :field="field"
                    v-model="formData[field.dataTableID]"
                  />
                </section>
              </div>
            </form>
          </template>

          <template v-if="dialog && action == 'create'">
            <form ref="formData" class="px-3">
              <section
                v-for="field in fields"
                :key="field.schemaID || field.dataTableID"
              >
                <InputField
                  :field="field"
                  v-model="formData[field.schemaID || field.dataTableID]"
                />
              </section>
            </form>
          </template>
        </v-card-text>
        <v-divider class="divider-margin-top"></v-divider>
        <v-card-actions class="v-card-filter-btns pb-4">
          <div class="d-flex py-3 pl-2">
            <v-btn
              @click="handleEntityModal('confirm')"
              color="info"
              class="mr-1"
            >
              <span v-if="!is_loading">{{ entityModalAction }}</span>
              <v-progress-circular
                v-if="is_loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </div>
          <div class="d-flex py-3 pl-2">
            <button @click="resetForm" class="btn btn-secondary mr-1">
              Reset
            </button>
            <button
              @click="handleEntityModal('cancel')"
              class="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "@/core/components/base/DataTable";
import { mapActions, mapState } from "vuex";
import Vue from "vue";

Vue.directive("blur", {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});

export default {
  name: "EntityPageContent",
  components: {
    InputField: () => import("@/core/components/base/InputField"),
    DataTable,
  },
  data() {
    return {
      page: null,
      dialog: false,
      action: "",
      formData: {},

      showEntityModal: false,
      showFilterModal: false,

      pagination: {
        limit: 10,
        skip: 0,
        total: 0,
        totalNoPages: 0,
        pageNo: null,
      },
      tenantList: [],
      currentTenant: {},
      filterField: false,
      viewButtonText: "view more",
    };
  },
  computed: {
    entityActions() {
      const allowedActions = ["create", "filter"];
      let availActions = [];

      if (this.currentEntity?.entity?.actions) {
        availActions = Object.keys(this.currentEntity?.entity?.actions).filter(
          (action) => allowedActions.includes(action)
        );
      }

      if (availActions.length > 0 && availActions.includes("filter")) {
        availActions.push("clear");
      }

      return availActions;
    },
    entityModalTitle() {
      switch (this.action) {
        case "create":
          return "Add record";
        case "filter":
          return "Filter record";
        default:
          return "";
      }
    },
    entityModalAction() {
      switch (this.action) {
        case "create":
          return "Add record";
        case "filter":
          return "Filter record";
        default:
          return "";
      }
    },
    headers() {
      let keys = [];
      if (this.currentEntity.keys) {
        keys = this.currentEntity.keys.reduce((acc, obj) => {
          if (obj.table?.displayOrder > 0) {
            acc.push({
              text: obj.label,
              tooltip: obj.tooltip || obj.label,
              display: obj.table.display,
              onHoverDisplay: obj.table.onHoverDisplay,
              value: obj.dataTableID || obj.schemaID,
              columnType: obj.table.columnType,
              link: obj.table.link || false,
              displaySeprator: obj.table.displaySeprator || ", ",
            });
          }
          return acc;
        }, []);

        if (keys) {
          keys = keys.sort((key1, key2) => {
            return key1.table?.displayOrder - key2.table?.displayOrder;
          });
        }

        keys.push({
          text: "",
          value: "actions",
          sortable: true,
        });
      }
      return keys;
    },
    fields() {
      let fields = [];

      // Filter the fields for action type
      if (this.action === "create") {
        fields = this.currentEntity.keys.filter((field) => {
          if (field.create) {
            return field;
          }
        });
      } else if (this.action === "filter") {
        fields = this.currentEntity.keys.filter((field) => {
          if (field.filter) {
            return field;
          }
        });
      }

      // Sort the fields based on order provided in enitity
      if (fields) {
        fields = fields.sort((field1, field2) => {
          return (
            field1[this.action].displayOrder - field2[this.action].displayOrder
          );
        });
      }

      return fields;
    },

    ...mapState({
      is_loading: (state) => state.is_loading,
      currentEntity: (state) => state.currentEntity,
      entityFilters: (state) => state.entityFilters,
      items: (state) => state.entityRows,
    }),
  },
  methods: {
    orderRecords(payload) {
      this.resetForm();
      this.initFiltersFormData();
      this.filterAndOrderEntityRecords({
        filterData: this.formData,
        orderColumn: payload,
        pagination: { pageNo: 1, limit: this.pagination.limit },
      }).then((result) => {
        this.pagination.total = result.meta.count;
        this.pagination.limit = result.meta.limit;
        this.pagination.skip = result.meta.skip;
        this.pagination.totalNoPages = Math.ceil(
          this.pagination.total / this.pagination.limit
        );
      });
    },
    toggleFilters() {
      this.filterField = !this.filterField;
      if (this.viewButtonText === "view more") {
        this.viewButtonText = "view less";
      } else {
        this.viewButtonText = "view more";
      }
    },
    toggleModal(status) {
      // this.showEntityModal =
      //   status === undefined ? !this.showEntityModal : status;
      this.dialog = status === undefined ? !this.showEntityModal : status;
    },
    updateTenantList(list) {
      this.tenantList = list;
    },
    removeChip(chipID) {
      this.removeFilter({
        chipID,
        pagination: { pageNo: 1, limit: this.pagination.limit },
      }).then((result) => {
        this.resetForm();
        this.initFiltersFormData();
        this.pagination.total = result.meta.count;
        this.pagination.limit = result.meta.limit;
        this.pagination.skip = result.meta.skip;
        this.pagination.totalNoPages = Math.ceil(
          this.pagination.total / this.pagination.limit
        );
        this.$router.push({ query: {} });
      });
    },

    reloadDetails() {
      this.reloadEntity({ pagination: this.pagination }).then((result) => {
        this.pagination.total = result.meta.count;
        this.pagination.limit = result.meta.limit;
        this.pagination.skip = result.meta.skip;
        this.pagination.totalNoPages = Math.ceil(
          this.pagination.total / this.pagination.limit
        );
      });
    },

    resetDetails() {
      this.resetEntity({
        pagination: { pageNo: 1, limit: this.pagination.limit },
      }).then((result) => {
        this.pagination.total = result.meta.count;
        this.pagination.limit = result.meta.limit;
        this.pagination.skip = result.meta.skip;
        this.pagination.totalNoPages = Math.ceil(
          this.pagination.total / this.pagination.limit
        );
        this.$router.push({ query: {} });
      });
    },

    initFiltersFormData() {
      // this.entityFilters
      this.entityFilters.forEach((filter) => {
        this.formData[filter.on] = filter.value;
      });
    },

    handleAction(action, payload) {
      this.action = action;
      switch (typeof action === "object" ? action.type : action) {
        case "create":
          this.toggleModal(true);
          break;
        case "filter":
          this.initFiltersFormData();
          this.toggleModal(true);
          break;
        case "reload":
          this.reloadDetails();
          break;
        case "order":
          this.orderRecords(payload);
          break;
        case "setCurrentTenant":
          alert("ppp");
          console.log(action);
          // this.setCurrentTenant()
          break;
        case "clear":
          this.resetDetails();
          break;
      }
    },

    rowAction(action, value) {
      switch (action) {
        case "view":
          this.$router.push({
            name: "entity-tabs-content",
            params: { entity: this.$route.params.entity, id: value },
          });
      }
    },

    loadCurrentEntity(newEntity, oldEntity) {
      if (newEntity !== oldEntity) {
        if (this.$route.query?.pageNo) {
          this.pagination.pageNo =
            this.$route.query?.pageNo === undefined
              ? 1
              : +this.$route.query?.pageNo;
          this.pagination.skip =
            this.$route.query?.pageNo === undefined
              ? 0
              : this.pagination.limit * (+this.$route.query?.pageNo - 1);
        }

        this.loadEntity({
          entity: newEntity,
          pagination: this.pagination,
        })
          .then((result) => {
            this.pagination.total = result.meta.count;
            this.pagination.limit = result.meta.limit;
            this.pagination.skip = result.meta.skip;
            this.pagination.totalNoPages = Math.ceil(
              this.pagination.total / this.pagination.limit
            );
          })
          .catch(() => {
            console.log("error");
          });
      }
    },

    handleEntityModal(eventName) {
      if (!this.is_loading) {
        switch (eventName) {
          case "confirm":
            this.confirmEvent();
            break;
          case "cancel":
            this.action = null;
            this.resetForm();
            this.toggleModal(false);
            break;
          case "confirmClose":
            this.action = null;
            this.resetForm();
            this.toggleModal(false);
        }
      }
    },

    resetForm() {
      this.formData = {};
    },

    confirmEvent() {
      switch (this.action) {
        case "filter":
          this.filterAndOrderEntityRecords({
            filterData: this.formData,
            pagination: { pageNo: 1, limit: this.pagination.limit },
            orderColumn: null,
          }).then((result) => {
            this.pagination.total = result.meta.count;
            this.pagination.limit = result.meta.limit;
            this.pagination.skip = result.meta.skip;
            this.pagination.totalNoPages = Math.ceil(
              this.pagination.total / this.pagination.limit
            );
            this.handleEntityModal("confirmClose");
          });
          break;
        case "create":
          this.addEntityRecord({ formData: this.formData }).then(() => {
            this.handleEntityModal("confirmClose");
          });
          break;
      }
    },

    loadQueryDetail(newQuery = null, oldQuery = null) {
      if (newQuery?.pageNo) {
        if (newQuery.pageNo !== oldQuery?.pageNo) {
          this.pagination.pageNo =
            newQuery.pageNo === undefined ? 1 : +newQuery.pageNo;
          this.pagination.skip =
            newQuery.pageNo === undefined
              ? 0
              : this.pagination.limit * (+newQuery.pageNo - 1);

          this.changePageEntityRows(this.pagination);
        }
      } else if (oldQuery?.pageNo) {
        this.pagination.skip = 0;
        this.pagination.pageNo = 1;

        this.changePageEntityRows(this.pagination);
      }
    },

    downloadCsv() {
      this.downloadEntityCsv();
    },

    ...mapActions([
      "loadEntity",
      "reloadEntity",
      "resetEntity",
      "removeFilter",
      "addEntityRecord",
      "changePageEntityRows",
      "downloadEntityCsv",
      "getTenantList",
      "setCurrentTenant",
      "filterAndOrderEntityRecords",
    ]),
    ...mapActions("tenant", ["getTenantList", "setCurrentTenant"]),
  },
  watch: {
    "$route.params.entity": {
      deep: true,
      immediate: true,
      handler: "loadCurrentEntity",
    },
    "$route.query": {
      deep: true,
      immediate: true,
      handler: "loadQueryDetail",
    },
    currentEntity: {
      deep: true,
      immediate: true,
      handler(newValue) {
        console.log(newValue);
        const tenant = newValue?.entity?.actions.tenant;
        console.log(tenant);
        if (tenant?.endPoint) {
          this.getTenantList(tenant)
            .then((tenantList) => {
              this.tenantList = tenantList;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
    currentTenant: function (newValue) {
      this.setCurrentTenant(newValue);
    },
  },
  destroyed() {
    this.setCurrentTenant("");
  },
};
</script>

<style lang="scss" scoped>
.filter-chips {
  display: flex;
  gap: 5px;
  list-style: none;
  margin: 0;
  padding: 0;
  &__chip {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: #e3e3e3;
    border-radius: 15px;
    color: #222;
    font-size: 1em;
    .c-icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.v-card-filter-btns {
  justify-content: space-between;
}

.divider-margin-top {
  margin-top: 58px;
}

.form-position {
  position: relative;
}

.view-more-btn {
  position: absolute;
  right: 18px;
}
</style>
